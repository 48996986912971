export enum DonorWorkupFormRoutes {
  DonorWorkupForm = 'donor-workup-form',
  SubsequentDonorWorkup = 'subsequent-donor-workup',
}

export enum DonorWorkupRouteParamKeys {
  DonorId = 'sourceId',
  WorkupId = 'workupId',
  PatientId = 'patientId',
  OrderId = 'orderId',
  Working = 'working',
}

export interface WorkupUrlParams {
  patientId: MsApp.Guid;
  [DonorWorkupRouteParamKeys.WorkupId]: MsApp.Guid;
  [DonorWorkupRouteParamKeys.DonorId]: MsApp.Guid;
  [DonorWorkupRouteParamKeys.OrderId]: MsApp.Guid;
}
